import React, { Component } from "react"

export default class Erreur404 extends Component {
  render() {
    return (
      <div>
        <h3>Cette page n&#39;existe pas.</h3>
      </div>
    )
  }
}
